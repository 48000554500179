/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react';

const SideMenu = ({children}) => (
    <div className="main-col main-left">
        {children}
    </div>
);

export default SideMenu;