import React from 'react';
import {ButtonGroup, Popover, Icon, Menu, Button, Navbar, Tabs, Tab, TabId, Switch, Intent, Spinner } from "@blueprintjs/core";

class Inspector extends React.Component {
    constructor(props) {
        super(props);
    }

    state: inspectorTabsState = {
        activePanelOnly: false,
        animate: true
    }

    render() {
        const infoIcon = (
            <i className="fal fa-info-circle" />
        );
        const licensingIcon = (
            <i className="fal fa-coins" />
        );
        const historyIcon = (
            <i className="fal fa-history" />
        );
        const exifIcon = (
            <i className="fal fa-camera" />
        );
        const compositionIcon = (
            <i className="fal fa-layer-group" />
        );
        const statsIcon = (
            <i className="fal fa-chart-line" />
        );

        const downloadThumbnail = (
            <div>Thumbnail<label>200 x 200</label></div>
        );
        const downloadMedium = (
            <div>Medium<label>600 x 600</label></div>
        );
        const downloadLarge = (
            <div>Large<label>1200 x 1200</label></div>
        );
        const downloadXlarge = (
            <div>X-Large<label>2000 x 2000</label></div>
        );
        const downloadFull = (
            <div>Rull Resolution<label>6000 x 4000</label></div>
        );

        return (
            <div className="inspector full-height column">
                <div className="column-titlebar">
                    <div className="column-title">Inspector</div>
                </div>
                <div className="inspector-asset">
                    <div className="inspector-asset-container">
                        <img src="/imgs/asset.jpg" title="" alt="" />
                    </div>
                </div>
                <div className="inspector-asset-details">
                    <div className="row align-center">
                        <div className="col-5">
                            <span><i className="fal fa-arrow-to-bottom" /> 163</span>
                            <span><i className="far fa-eye" /> 2.8K</span>
                        </div>
                        <div className="col-7 text-right">
                            <ButtonGroup>
                                <Popover>
                                    <Button text="Download" intent="secondary" className="bp3-small" />
                                    <Menu className="download-options">
                                        <Menu.Item multiline="true" text={downloadThumbnail} />
                                        <Menu.Item multiline="true" text={downloadMedium} />
                                        <Menu.Item multiline="true" text={downloadLarge} />
                                        <Menu.Item multiline="true" text={downloadXlarge} />
                                        <Menu.Item multiline="true" text={downloadFull} />
                                    </Menu>
                                </Popover>
                                <Button text="Share" intent="secondary" className="primary bp3-small" />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                <div className="inspector-tabs">
                    <Tabs id="inspectorTabs" animate={this.state.animate} renderActiveTabPanelOnly="{this.state.activePanelOnly}" className="tabs-fill">
                        <Tab id="ipInfo" title={infoIcon} panel={<InfoPanel />} />
                        <Tab id="ipLicensing" title={licensingIcon} panel={<LicensingPanel />} />
                        <Tab id="ipHistory" title={historyIcon} panel={<HistoryPanel />} />
                        <Tab id="ipExif" title={exifIcon} panel={<ExifPanel />} />
                        <Tab id="ipComposite" title={compositionIcon} panel={<CompositePanel />} />
                        <Tab id="ipStats" title={statsIcon} panel={<StatsPanel />} />
                    </Tabs>
                </div>
            </div>
        );
    }
}

const InfoPanel: React.SFC<{}> = () => (
    <div className="inspector-pane">
        <div className="row">
            <div className="col nopadding"><label>Title</label><div className="idata">This would be a realy long title shown</div></div>
        </div>
        <div className="row">
            <div className="col nopadding"><label>Filename</label><div className="idata">flower_closeup_pink.jpg</div></div>
        </div>
        <div className="row">
            <div className="col-4"><label>Size</label><div className="idata">37MB</div></div>
            <div className="col-4"><label>Orientation</label><div className="idata">Landscape</div></div>
            <div className="col-4"><label>Dimensions</label><div className="idata">6000 x 4000</div></div>
        </div>
        <div className="row">
            <div className="col-4"><label>Resolution</label><div className="idata">300dpi</div></div>
            <div className="col-4"><label>Color</label><div className="idata">sRGB</div></div>
            <div className="col-4"><label>File Type</label><div className="idata">JPG</div></div>
        </div>
    </div>
);
const LicensingPanel: React.SFC<{}> = () => (
    <div className="inspector-pane">
        <p>Licensing panel</p>
    </div>
);
const HistoryPanel: React.SFC<{}> = () => (
    <div className="inspector-pane">
        <p>History panel</p>
    </div>
);
const ExifPanel: React.SFC<{}> = () => (
    <div className="inspector-pane">
        <p>Exif panel</p>
    </div>
);
const CompositePanel: React.SFC<{}> = () => (
    <div className="inspector-pane">
        <p>Composite panel</p>
    </div>
);
const StatsPanel: React.SFC<{}> = () => (
    <div className="inspector-pane">
        <p>Stats panel</p>
    </div>
);

export default Inspector;
