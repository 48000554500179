/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import { AUTH_SWITCH_INSTANCE } from './auth'
import update from 'immutability-helper'

export const UI_OPEN_COLLAPSIBLE = 'ui/OPEN_COLLAPSIBLE'
export const UI_CLOSE_COLLAPSIBLE = 'ui/CLOSE_COLLAPSIBLE'

export const UI_OPEN_OVERLAY = 'ui/OPEN_OVERLAY'
export const UI_CLOSE_OVERLAY = 'ui/CLOSE_OVERLAY'

export const UI_TAB_SELECT = 'ui/TAB_SELECT'

const initialState = {
    collapsibles : new Map(),

    openOverlay : false,
    overlays : new Map(),

    tabs : new Map(),
}

export const openCollapsible = (itemId) => {
    return { type: UI_OPEN_COLLAPSIBLE, itemId }
}

export const closeCollapsible = (itemId) => {
    return { type: UI_CLOSE_COLLAPSIBLE, itemId }
}

export const openOverlay = (overlayId) => {
    return { type: UI_OPEN_OVERLAY, overlayId }
}

export const closeOverlay = (overlayId) => {
    return { type: UI_CLOSE_OVERLAY, overlayId }
}

export const tabSelect = (tabId, selection) => {
    return { type: UI_TAB_SELECT, tabId, selection }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SWITCH_INSTANCE:
            return {
                ...initialState
            }

        case UI_OPEN_COLLAPSIBLE:
            return update(state, {collapsibles : {[action.itemId] : {$set : true}}});
        case UI_CLOSE_COLLAPSIBLE:
            return update(state, {collapsibles : {$remove : [action.itemId]}});

        case UI_OPEN_OVERLAY:
            return update(state, {
                overlays : {[action.overlayId] : {$set : true}},
                openOverlay : {$set : action.overlayId}
            });
        case UI_CLOSE_OVERLAY:
            return update(state, {
                overlays : {$remove : [action.overlayId]},
                openOverlay : {$set : false}
            });

        case UI_TAB_SELECT:
            return update(state, {tabs : {[action.tabId] : {$set : action.selection}}})

        default:
            return state
    }
}