/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { login } from '../modules/auth';

import { FormGroup, InputGroup, Button, Intent } from '@blueprintjs/core';
import { Redirect } from 'react-router-dom';

import SideMenu from '../framework/sidemenu';
import SectionHeader from '../framework/sectionheader';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = { username : '', password : '' };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const inputName = event.target.name;
        let update = {};
        update[inputName] = event.target.value;
        this.setState(update);
    }

    handleSubmit(event) {
        this.props.login(this.state);
        event.preventDefault();
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        if (this.props.loggedIn) {
            if (from.pathname && from.pathname != '/') {
                return (
                    <Redirect to={from.pathname} />
                );
            } else {
                return (
                    <div className="main">
                        <SideMenu />
                        <div id="main" className="main-col main-center">
                            <SectionHeader title="Welcome" />
                            <div>
                                Please select a section from the menu above.
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="main">
                    <SideMenu />
                    <div id="main" className="main-col main-center">
                        <SectionHeader title="Login" />
                        <form onSubmit={this.handleSubmit} id="login-form">
                            <FormGroup label="Username" labelFor="loginUsername" labelInfo="(required)">
                                <InputGroup id="loginUsername" placeholder="Username or email" name="username" value={this.state.username} onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup label="Password" labelFor="loginPassword" labelInfo="(required)">
                                <InputGroup id="loginPassword" placeholder="Password" name="password" value={this.state.password} type="password" onChange={this.handleChange} />
                            </FormGroup>
                            <Button intent={Intent.SUCCESS} type="submit" text="Login" />
                        </form>
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    loggedIn : state.auth.loggedIn
})

const mapDispatchToProps = dispatch => bindActionCreators({
    login
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)
