/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react';
import { Spinner } from "@blueprintjs/core";
import { VHCenter } from "./layout";

export const LoadingModal = (props) => (
    <div className="loading-data">
        <div className="loading-icon">
            <i className="fa fa-spinner fa-spin"></i>
            {props.message && (<div>{props.message}</div>)}
        </div>
    </div>
);

export const LoadingApp = (props) => (
    <VHCenter>
        <Spinner size={Spinner.LARGE} />
    </VHCenter>
);


// <i className="fa fa-spinner fa-spin"></i>
// {props.message && (<div>{props.message}</div>)}