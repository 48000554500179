/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react';
import { connect } from 'react-redux';

import UserMenu from '../components/usermenu';
import ToolMenu from '../components/toolmenu';

import { Classes, Button, InputGroup, Icon, Menu, MenuDivider, MenuItem, Popover, Elevation, Tag, Overlay } from "@blueprintjs/core";

class Link extends React.Component {
    render() {
        return (
            <a href={this.props.to}>{this.props.children}</a>
        )
    }
}

class Header extends React.Component {
    render() {
        let { loggedIn, username, session, location } = this.props;

        const accountImage = (
            <img src="/imgs/account.png" className="account"  />
        );

        const iconColumns = (
            <i className="fal fa-archive" />
        );

        const advancedSearch = (
            <div>
            <Button intent="minimal" text="Advanced" rightIcon="chevron-down" />
            <Overlay onClose={this.handleClose}>
                <div className="">
                    <h3>Advanced Search</h3>
                    <div className="">
                        <Button intent="" onClick="" style="">Close</Button>
                        <Button intent="primary" onClick="" style="">Search</Button>
                    </div>
                </div>
            </Overlay>
            </div>
        );

        return (
            <div className="header">
                <div className="header-col header-left">
                    <div className="header-app-logo">{accountImage}</div>
                    <div className="header-stage-option">
                    {loggedIn &&
                        <ToolMenu />
                    }
                    </div>
                </div>
                <div className="header-col header-center text-center">
                    <InputGroup
                        className="search"
                        round="true"
                        leftIcon="search"
                        placeholder="What are you looking for?"
                        rightElement={advancedSearch}
                    />
                </div>
                <div className="header-col header-right">
                    <div className="row align-center">
                        <div className="col-8">
                            {loggedIn &&
                                <UserMenu username={username} />
                            }
                        </div>
                        <div className="col-4 text-right">
                            <Tag intent="danger" round="true">15</Tag>
                            <Icon icon="notifications" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedIn : state.auth.loggedIn,
    username : state.auth.username,
    session : state.auth.session,
    acls : state.auth.acls,
});

export default connect(
    mapStateToProps
)(Header);
