/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import fetch from 'isomorphic-fetch';
import values from 'object.values';

if (!Object.values) {
    values.shim();
}

async function loginRequest(auth, username, password) {
    try {
        let params = {
            username,
            password,
            client_id : auth.clientId,
            client_secret : auth.clientSecret,
            grant_type : 'password',
        };

        let form = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k].toString()))
            .join('&');

        const res = await fetch(auth.apiUrl + "/auth/login", {
            method : "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                'Accept': 'application/json, application/xml, text/html, *.*',
            },
            body: form
        });

        if (res.status !== 200) {
            return false;
        } else {
            const json = await res.json();
            return json;
        }
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

async function getRequest(auth, url, params) {
    try {
        let query = '';
        if (params) {
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            query = '?' + query;
        }

        const res = await fetch(auth.apiUrl + url + query, { headers: { 'Bearer' : auth.bearerToken }});
        if (res.status !== 200) {
            return false;
        } else {
            const json = await res.json();
            return json;
        }
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

async function putRequest(auth, url, payload) {
    try {
        const res = await fetch(auth.apiUrl + url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            headers: { 'Bearer' : auth.bearerToken },
            body: JSON.stringify(payload)
        });
        if (res.status !== 200) {
            return false;
        } else {
            const json = await res.json();
            return json;
        }
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

async function postRequest(auth, url, payload) {
    try {
        const res = await fetch(auth.apiUrl + url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: { 'Bearer' : auth.bearerToken },
            body: JSON.stringify(payload)
        });
        if (res.status !== 200) {
            return false;
        } else {
            const json = await res.json();
            return json;
        }
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

export default {
    loginRequest,
    getRequest,
    putRequest,
    postRequest,
};