/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import { combineReducers } from 'redux'
import auth from './auth'
import ui from './ui'
import studio from './studio'

export default combineReducers({
  auth,
  ui,
  studio
})