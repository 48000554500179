/**
 * @copyright Lookbooks Media, Inc. 2019
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { switchInstance, logout } from '../modules/auth';
import { studioTreeClick, studioTreeExpand, studioTreeCollapse } from '../modules/studio';

import { Spinner, Classes, Icon, Intent, ITreeNode, Position, Tooltip, Tree, Button } from "@blueprintjs/core";

class StudioTree extends React.Component {
    constructor(props) {
        super(props);
    }

    handleNodeClick = (nodeData, nodePath) => {
        this.props.studioTreeClick(nodeData, nodePath);
    }

    handleNodeCollapse = (nodeData, nodePath) => {
        this.props.studioTreeCollapse(nodeData, nodePath);
    }

    handleNodeExpand = (nodeData, nodePath) => {
        this.props.studioTreeExpand(nodeData, nodePath);
    }

    render() {

        const iconAdd = (
            <i className="fal fa-plus" />
        );
        const iconLightbox = (
            <i className="fa fa-archive" />
        );
        const iconDelete = (
            <i className="fa fa-trash" />
        );

        return (
            <React.Fragment>
                {this.props.treeLoading && <Spinner size={Spinner.LARGE} />}
                {(!this.props.treeLoading && this.props.tree) &&
                    <div className="library column">
                        <div className="column-titlebar">
                            <div className="column-title">
                                <Button icon={iconAdd} className="float-right bp3-small" />
                                Library
                            </div>
                        </div>
                        <div className="library-archive">
                            <Tree
                                contents={this.props.treeNodes}
                                onNodeClick={this.handleNodeClick}
                                onNodeCollapse={this.handleNodeCollapse}
                                onNodeExpand={this.handleNodeExpand}
                            />
                        </div>
                        <div className="library-bottom">
                            <Button icon={iconLightbox} text="Lightbox" alignText="left" intent="minimal" fill="true" />
                            <Button icon={iconDelete} text="Deleted" alignText="left" intent="minimal" fill="true" />
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    acls : state.auth.acls,
    tree : state.studio.tree,
    treeNodes : state.studio.treeNodes,
    treeLoading : state.studio.treeLoading,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    studioTreeClick,
    studioTreeExpand,
    studioTreeCollapse,

    switchInstance,
    logout,
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(StudioTree));