/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react';

const centerStyle = {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
};

export const VHCenter = (props) => (
    <div style={centerStyle}>{props.children}</div>
);
