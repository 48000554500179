/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react';
import { switchInstance, logout } from '../modules/auth';
import { Popover, Button, Menu, MenuItem, MenuDivider, Position } from '@blueprintjs/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectInstance = this.handleSelectInstance.bind(this);
        this.handleRoute = this.handleRoute.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleSelectInstance(slug) {
        this.props.switchInstance(slug);
    }

    handleRoute(slug) {
        this.props.history.push(slug);
    }

    handleLogout() {
        this.props.logout();
    }

    render() {
        let { loggedIn, username, session, location } = this.props;

        const welcomeText = 'Hi ' + username;

        const avatarImage = (
            <img src="/imgs/avatar.jpg" className="avatar noborder noshadow"  />
        );

        const planType = (
            <div className="planType">
                <div className="pie-graph float-right">
                    <div className="pie-graph-fill progress-15"></div>
                </div>
                Pro Plan<br/>15% - 21.5GB of 1TB
            </div>
        );

        const menuItems = this.props.acls.map((acl) => {
            return (
                <MenuItem text={acl.name} key={acl.slug} onClick={this.handleSelectInstance.bind(this, acl.slug)} />
            );
        });

        const MenuContent = (
            <div style={{ maxHeight: '50vh', overflowY : 'auto' }}>
                <Menu>
                    <MenuItem  multiline="true" text={planType} disabled="true" />
                    <MenuDivider />
                    <MenuItem icon="person" text="Manage Users" />
                    <MenuItem icon="cog" text="My Account" onClick={this.handleRoute.bind(this, '/user')} />
                    {menuItems.length > 1 && (
                        <React.Fragment>
                            <MenuDivider title="Switch Instance" />
                            {menuItems}
                        </React.Fragment>
                    )}
                    <MenuDivider />
                    <MenuItem icon="log-out" text="Log Out" onClick={this.handleLogout} />
                </Menu>
            </div>
        );

        return (
            <Popover content={MenuContent} position={Position.BOTTOM_RIGHT}>
                <Button icon={avatarImage} intent="minimal" text={welcomeText} />
            </Popover>
        );
    }
}

const mapStateToProps = state => ({
    acls : state.auth.acls,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    switchInstance,
    logout,
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserMenu));