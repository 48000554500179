/**
 * @copyright Lookbooks Media, Inc. 2019
 */

import lbapi from './lbapi';
import { AUTH_SWITCH_INSTANCE, AUTH_LOGOUT } from './auth';
import update from 'immutability-helper';

export const STUDIO_LOAD_TREE = 'studio/LOAD_TREE';

export const STUDIO_TREE_LOAD = 'studio/TREE_LOAD';
export const STUDIO_TREE_LOADED = 'studio/TREE_LOADED';

export const STUDIO_TREE_CLICK = 'studio/TREE_CLICK';
export const STUDIO_TREE_EXPAND = 'studio/TREE_EXPAND';
export const STUDIO_TREE_COLLAPSE = 'studio/TREE_COLLAPSE';

export const STUDIO_CONTENT_LOAD = 'studio/CONTENT_LOAD';
export const STUDIO_CONTENT_LOADED = 'studio/CONTENT_LOADED';

export const STUDIO_CONTAINER_LOAD = 'studio/CONTAINER_LOAD';
export const STUDIO_CONTAINER_LOADED = 'studio/CONTAINER_LOADED';

const initialState = {
    search : '',
    matches : [],

    forceRefresh : false,

    tree : false,
    treeNodes : [],
    treeLoading : false,

    currentContentId : false,
    loadingContentId : false,
    contents : {},

    currentContainerId : false,
    loadingContainerId : false,
}

export const getDefaultMenuBranches = () => {
    return async (dispatch, getStore) => {
        dispatch({ type: STUDIO_TREE_LOAD });
        await getMenuBranches(dispatch, getStore);
    }
}

const getMenuBranches = async (dispatch, getStore) => {
    let store = getStore();
    let params = [];

    let response = await lbapi.getRequest(
        store.auth,
        '/containers/get_menu_branches',
        params
    );

    if (response) {
        dispatch({ type : STUDIO_TREE_LOADED, payload : response.data });
        return response;
    } else {
        console.log('Error loading studio tree');
        return false;
    }
}

const processContent = (content) => {
    return {
        id : content.id,
        studioType : "content",
        label : (content.name === '' ? '(no name)' : content.name),
        hasCaret : false,
        isExpanded : false,
        icon : "document",
    };
}

const processContainer = (container) => {
    let containerNode = {
        id : container.id,
        studioType : "container",
        hasCaret : false,
        icon : "folder-close",
        isExpanded : false,
        label : (container.name === '' ? '(no name)' : container.name),
        childNodes : [],
    };

    if (container.hasOwnProperty('subcontainers')) {
        for (const subid in container.subcontainers) {
            containerNode.childNodes.push(processContainer(container.subcontainers[subid]));
        }
    }

    if (container.hasOwnProperty('contents')) {
        for (const subid in container.contents) {
            containerNode.childNodes.push(processContent(container.contents[subid]));
        }
    }

    containerNode.hasCaret = (containerNode.childNodes.length > 0);

    return containerNode;
}

const processMenuBranches = (data) => {
    let treeNodes = data.map((container) => {
        return processContainer(container);
    });
    return treeNodes;
};

export const studioTreeClick = (nodeData, nodePath) => {
    return async (dispatch, getStore) => {
        dispatch({ type : STUDIO_TREE_CLICK, nodeData, nodePath });
        if (nodeData.studioType === "content") {
            await getContent(dispatch, getStore, nodeData.id);
        }
    }
}


export const studioTreeExpand = (nodeData, nodePath) => {
    return { type : STUDIO_TREE_EXPAND, nodeData, nodePath }
}

export const studioTreeCollapse = (nodeData, nodePath) => {
    return { type : STUDIO_TREE_COLLAPSE, nodeData, nodePath }
}

const nodePathToCommandPath = (topElement, nodePath, updateCommand) => {
    let commands = updateCommand;

    nodePath.reverse().forEach((nodeIndex) => {
        commands = { childNodes : {[nodeIndex] : commands }};
    });

    // change the top reference from `childNodes` to `treeNodes`
    commands[topElement] = commands['childNodes'];
    delete commands.childNodes;

    return commands;
}

const getContent = async (dispatch, getStore, contentId) => {
    let store = getStore();
    let params = [];

    dispatch({ type: STUDIO_CONTENT_LOAD, contentId });

    let response = await lbapi.getRequest(
        store.auth,
        '/contents/' + contentId + '/assets',
        params
    );

    if (response) {
        dispatch({ type : STUDIO_CONTENT_LOADED, contentId, payload : response.data });
        return response;
    } else {
        console.log('Error loading content data');
        return false;
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STUDIO_TREE_LOAD:
            return update(state, {
                treeLoading : {$set : true},
            });
        case STUDIO_TREE_LOADED:
            return update(state, {
                treeLoading : {$set : false},
                tree : {$set : action.payload},
                treeNodes : {$set : processMenuBranches(action.payload)}
            });

        case STUDIO_TREE_CLICK:
            // return update(state, nodePathToCommandPath('treeNodes', action.nodePath,
            //     { isSelected : { $set : !action.nodeData.isSelected } }
            // ));
            return state;
        case STUDIO_TREE_EXPAND:
            return update(state, nodePathToCommandPath('treeNodes', action.nodePath,
                { isExpanded : { $set : true } }
            ));
        case STUDIO_TREE_COLLAPSE:
            return update(state, nodePathToCommandPath('treeNodes', action.nodePath,
                { isExpanded : { $set : false } }
            ));

        case STUDIO_CONTENT_LOAD:
            return update(state, {
                loadingContentId : { $set : action.contentId },
            });
        case STUDIO_CONTENT_LOADED:
            return update(state, {
                loadingContentId : { $set : false },
                currentContentId : { $set : action.contentId },
                contents : { [action.contentId] : { $set : action.payload.content[0] } },
            });

        case STUDIO_CONTAINER_LOAD:
            return state;
        case STUDIO_CONTAINER_LOADED:
            return state;

        case AUTH_LOGOUT:
        case AUTH_SWITCH_INSTANCE:
            return update(initialState, {
                forceRefresh : {$set : true}
            });
        default:
            return state;
    }
}
