/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import lbapi from './lbapi'

export const AUTH_INITIALIZE = 'auth/INITIALIZE'
export const AUTH_LOGIN = 'auth/LOGIN'
export const AUTH_LOGOUT = 'auth/LOGOUT'
export const AUTH_SESSION = 'auth/SESSION'
export const AUTH_CONFIGURATION = 'auth/CONFIGURATION'
export const AUTH_ACLS = 'auth/ACLS'
export const AUTH_SWITCH_INSTANCE = 'auth/SWITCH_INSTANCE'

const initialState = {
    initialized : false,
    loggedIn : false,
    username : '',
    apiUrl : '',
    clientId : '',
    clientSecret : '',
    bearerToken : '',
    refreshToken : '',
    tokenExpires : 0,
    session : {},
    configuration : {
        ArtistCanSeeSBI : false,
    },
    calendarId : false,
    acls : [],
}

const getLocalState = () => {
    try {
        const tokens = JSON.parse(localStorage.getItem('lbapi.tokens')) || undefined;
        return tokens;
    } catch (err) {
        return undefined;
    }
}

const setLocalState = (bearerToken, refreshToken, tokenExpires) => {
    try {
        let tokens = { bearerToken, refreshToken, tokenExpires };
        localStorage.setItem('lbapi.tokens', JSON.stringify(tokens));
        return true;
    } catch (err) {
        return undefined;
    }
}

const clearLocalState = () => {
    try {
        localStorage.removeItem('lbapi.tokens');
        return true;
    } catch (err) {
        return undefined;
    }
}

export const initializeOnStart = () => {
    return (dispatch, getState) => {
        let state = getState();
        if (!state.auth.initialized) {
            let payload = {
                // apiUrl : 'http://lbapi',
                // apiUrl : 'http://lbapiwip',
                apiUrl : 'https://api.lkbks.com',
                clientId : '47225ccc-51df-11e5-a86c-22000aa46860',
                clientSecret : '1ee221c0ec50592e7c8a52148cad6daec645',
                bearerToken : '',
            }

            // if local environment has LBPro credentials, use them instead
            if (window.hasOwnProperty('professional')) {
                if (window['professional'].hasOwnProperty('lbapi_url')) payload.apiUrl = window['professional'].lbapi_url;
                if (window['professional'].hasOwnProperty('lbapi_access_token')) payload.bearerToken = window['professional'].lbapi_access_token;
                if (window['professional'].hasOwnProperty('lbapi_client_id')) payload.clientId = window['professional'].lbapi_client_id;
                if (window['professional'].hasOwnProperty('lbapi_client_secret')) payload.clientSecret = window['professional'].lbapi_client_secret;
            }

            // if we didn't get a bearer token from the window state, check local storage
            if (payload.bearerToken === '') {
                let local = getLocalState();
                if (local) payload = {...payload, ...local};
            } else {
                // clear local state if we were force-given an access token
                clearLocalState();
            }

            payload.loggedIn = (payload.bearerToken !== '');

            dispatch({ type : AUTH_INITIALIZE, payload });

            if (payload.loggedIn) {
                dispatch(getSession());
            }
        }
    }
}

export const login = (data) => {
    return async (dispatch, getStore) => {
        let store = getStore();
        let response = await lbapi.loginRequest(store.auth, data.username, data.password);
        if (response) {
            dispatch({ type: AUTH_LOGIN, payload : { ...response.data.token, username : data.username } });
            dispatch(getSession());
        } else {
            dispatch({ type: AUTH_LOGOUT });
        }
    }
}

export const getAcls = () => {
    return async (dispatch, getStore) => {
        let response = await lbapi.getRequest(getStore().auth, '/session/acls');
        if (response) {
            dispatch({ type: AUTH_ACLS, payload : response.data });
        } else {
            dispatch(logout());
        }
    }
}

export const getSession = () => {
    return async (dispatch, getStore) => {
        let response = await lbapi.getRequest(getStore().auth, '/session/details');
        if (response) {
            dispatch({ type: AUTH_SESSION, payload : response.data });
            dispatch(getAcls());
            dispatch(getConfiguration());
        } else {
            dispatch(logout());
        }
    }
}

export const getConfiguration = () => {
    return async (dispatch, getStore) => {
        let response = await lbapi.getRequest(getStore().auth, '/settings/configuration');
        if (response) {
            dispatch({ type: AUTH_CONFIGURATION, payload : response.configuration });
        } else {
            dispatch(logout());
        }
    }
}

export const logout = () => {
    return { type : AUTH_LOGOUT }
}

export const switchInstance = (slug) => {
    return async (dispatch, getStore) => {
        let store = getStore();
        let response = await lbapi.getRequest(store.auth, '/session/select/' + slug);
        if (response) {
            dispatch({ type : AUTH_SWITCH_INSTANCE, payload : { ...response.data.token } });
            dispatch(getSession());
        } else {
            dispatch(logout());
        }
    }
}

const processTokens = (data) => {
    let tokenExpires = Date.now() + (data.expires_in * 1000),
        bearerToken = data.access_token,
        refreshToken = data.refresh_token;

    setLocalState(bearerToken, refreshToken, tokenExpires);

    return { bearerToken, refreshToken, tokenExpires };
}

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_INITIALIZE:
            return {
                ...state,
                initialized : true,
                ...action.payload,
            }
        case AUTH_LOGIN:
            let loginTokens = processTokens(action.payload);
            return {
                 ...state,
                 username : action.payload.username,
                 ...loginTokens,
            }
        case AUTH_LOGOUT:
            clearLocalState();
            return {
                ...state,
                loggedIn : false,
                username : '',
                bearerToken : '',
                refreshToken : '',
                tokenExpires : 0,
                session : {},
                acls : [],
            }
        case AUTH_SESSION:
            let calendarId = null;
            if (action.payload.agent) {
                calendarId = action.payload.agent.contact_id;
            }
            action.payload['bucket'] = action.payload.acl.slug.replace('.', '/');
            return {
                ...state,
                username : action.payload.user.screen_name,
                calendarId : calendarId,
                loggedIn : true,
                session : action.payload,
            }
        case AUTH_CONFIGURATION:
            return {
                ...state,
                configuration : action.payload,
            }
        case AUTH_ACLS:
            return {
                ...state,
                acls : action.payload.acls,
            }
        case AUTH_SWITCH_INSTANCE:
            let switchTokens = processTokens(action.payload);
            return {
                ...state,
                ...switchTokens,
                session : {},
                acls : [],
            }
        default:
            return state
    }
}