/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react';

// image prefix sizes to be used in image size calculations
const imagePrefixes = {
    tn: {width: 55, height: 55},
    tnSm: {width: 120, height: 200},
    sm: {width: 80, height: 80},
    md: {width: 210, height: 300},
    lg: {width: 686, height: 514},
    ls: {width: 290, height: 193},
    pt: {width: 145, height: 193},
    gtn: {width: 300, height: 300},
    gs: {width: 1024, height: 768},
    gm: {width: 1440, height: 900},
    gl: {width: 1680, height: 1050},
    gxl: {width: 1920, height: 1080},
    gxxl: {width: 2560, height: 1440},
    ms: {width: 320, height: 240},
    mm: {width: 480, height: 320},
    ml: {width: 800, height: 600}
};

let videoSizes = [
    {resolution: 7680, label: '8k', prefix: '8k'},
    {resolution: 4096, label: '4k', prefix: '4k'},
    {resolution: 2048, label: '2k', prefix: '2k'},
    {resolution: 1920, label: 'FullHD', prefix: 'fullhd'},
    {resolution: 1280, label: 'HD', prefix: 'hd'},
    {resolution: 720, label: 'SD', prefix: 'video'},
];

const fileExtensionRegex = new RegExp('\.(jpg|jpeg|gif|png|mov|avi|wmv|mp4|3GP|3G2|mj2|m4v|flv|mpg|flv|asf|tif|tiff)$', 'i');

function getImageUrl(asset, opts) {
    let p = opts.prefix || 'lg';
    let filename = asset.filename;
    if (asset.asset_type_id === '2') {
        filename = filename.substr(0, filename.lastIndexOf('.')) + '.jpg';
    }
    return 'https://s3.amazonaws.com/' + opts.bucket + '/' + p + '_' + filename;
}

class Asset extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        let asset = this.props.data;
        let bucket = this.props.session.bucket;

        let prefix = "tnSm";
        let filename = asset.filename.substr(0, asset.filename.lastIndexOf('.')) + '.jpg';
        let path = 'https://s3.amazonaws.com/' + bucket + '/';
        let url = path + prefix + "_" + filename;

        let height = imagePrefixes[prefix].height;
        let width = imagePrefixes[prefix].width;

        return (
            <div style={{ float : 'left', width : width + 'px', height : height + 'px', margin: '2px' }}>
                <img src={url} />
            </div>
        );
    }
}

export default Asset;