/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react'

const SectionHeader = props => (
    <div className="section-header">
        <h3 className="column-title">{props.title}</h3>
        {props.showSearch &&
            <div className="column-search">
                <form>
                    <div className="form-group form-search">
                        <div className="input-group input-combo col-md-12">
                            <input type="search" name="search" className="col-md-10" placeholder="Search for something..." />
                            <button className="btn btn-default col-md-2"><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        }
    </div>
);

export default SectionHeader;