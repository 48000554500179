/**
 * @copyright Lookbooks Media, Inc. 2019
 */

import React from 'react';
import { ButtonGroup, Button, Intent, Slider, HTMLSelect, Menu, MenuItem, Popover, Elevation, Spinner } from "@blueprintjs/core";
import Asset from '../components/asset';

export interface ISliderState {
    value1?: number;
}

class ContentView extends React.Component {
    constructor(props) {
        super(props);
    }

    getChangeHandler(key: string) {
        return (value: number) => this.setState({ [key]: value });
    }

    state: ISliderState = {
        value1: 5,
    };

    render() {

        const menuRating = (
            <div className="ratings rating-menu">
                <span className="fa fa-star rated"></span>
                <span className="fa fa-star rated"></span>
                <span className="fa fa-star rated"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
            </div>
        );

        const menuLabel = (
            <div className="statuses status-menu">
                <span className="status-color-white"></span>
                <span className="status-color-red"></span>
                <span className="status-color-orange sel"></span>
                <span className="status-color-yellow"></span>
                <span className="status-color-green"></span>
                <span className="status-color-purple"></span>
                <span className="status-color-blue"></span>
            </div>
        );

        const iconTogglePanelLeft = (<i className="fal fa-arrow-to-left" />);
        const iconTogglePanelRight = (<i className="fal fa-arrow-to-right" />);
        const iconBars = (<i className="fal fa-bars" />);
        const iconUpload = (<i className="far fa-cloud-upload-alt" />);
        const iconFilter = (<i className="fal fa-filter" />);
        const iconMasonry = (<i className="fas fa-th" />);
        const iconGrid = (<i className="fal fa-th" />);

        let assets = [];
        if (!this.props.loading && this.props.content) {
            for (var aindex in this.props.content.assets) {
                assets.push(<Asset key={this.props.content.assets[aindex].id} data={this.props.content.assets[aindex]} session={this.props.session} />);
            }
        }
        return (
            <React.Fragment>
                {this.props.loading && <Spinner size={Spinner.LARGE} />}
                {!this.props.loading && this.props.content &&
                    <div id="Assets" className="main-assets full-height">
                        <div className="toolbar">
                            <div className="row">
                                <div className="col col-4">
                                    <ButtonGroup>
                                        <Button icon={iconTogglePanelLeft} className="bp3-small" />
                                        <Popover className="toolbar-menu">
                                            <Button icon={iconBars} className="bp3-small" />
                                            <Menu>
                                                <Menu.Item text="Edit" />
                                                <Menu.Item text="Workflow" />
                                                <Menu.Item text="Copy to..." />
                                                <Menu.Item text={menuRating} />
                                                <Menu.Item text={menuLabel} />
                                                <Menu.Divider />
                                                <Menu.Item text="Delete" />
                                            </Menu>
                                        </Popover>
                                    </ButtonGroup>
                                    <span className="spacer" />
                                    <Button icon={iconUpload} text="Upload" intent="secondary" className="bp3-small" />
                                </div>
                                <div className="col col-8 text-right">
                                    <Button icon={iconFilter} className="bp3-small" />
                                    <span className="spacer" />
                                    <HTMLSelect defaultValue="most-recent" className="bp3-small">
                                        <option value="most-recent">Most Recent</option>
                                        <option value="oldest">Oldest</option>
                                        <option value="file-type">File Type</option>
                                    </HTMLSelect>
                                    <span className="spacer" />
                                    <ButtonGroup>
                                        <Button icon={iconGrid} className="bp3-small" />
                                        <Button icon={iconMasonry} className="bp3-small" />
                                    </ButtonGroup>
                                    <span className="spacer" />
                                    <Slider className="slider slider-small"
                                        min={1}
                                        max={10}
                                        stepSize={1}
                                        value={this.state.value1}
                                        labelRenderer={false}
                                        showTrackFill={false}
                                        onChange={this.getChangeHandler("value1")}
                                    />
                                    <span className="spacer" />
                                    <Button icon={iconTogglePanelRight} className="bp3-small" />
                                </div>
                            </div>
                        </div>
                        <div className="statusbar">
                            <div className="row">
                                <div className="col col-8">All Files / 2018 / September / Vogue Campaign September 24</div>
                                <div className="col col-4 text-right">Showing 63 Assets</div>
                            </div>
                        </div>
                        <div className="assets-list">
                            {assets}
                        </div>
                        <div id="AssetsUploader" className="assets-uploader">
                            <div className="upload-message"><i className="far fa-images" /> Drop assets here to upload</div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default ContentView;