/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react';
import { connect } from 'react-redux';

import SideMenu from '../framework/sidemenu';
import SectionHeader from '../framework/sectionheader';

const User = props => (
    <React.Fragment>
        <div className="main">
            <div id="inspector" className="main-col main-left">
                <SideMenu />
            </div>
            <div id="main" className="main-col main-center">
                <SectionHeader title="Your details" />
                <div>
                    {props.session.user &&
                        <ul style={{listStyle : 'none', margin: '0', padding: '0'}}>
                            <li>Username: {props.session.user.screen_name}</li>
                            <li>Email: {props.session.user.username}</li>
                        </ul>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
);

const mapStateToProps = state => ({
    session : state.auth.session
});

export default connect(
    mapStateToProps
)(User);
