/**
 * @copyright Lookbooks Media, Inc. 2019
 */

import React from 'react';
import { switchInstance, logout } from '../modules/auth';
import { Popover, Button, Menu, MenuItem, MenuDivider, Position } from '@blueprintjs/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

class ToolMenu extends React.Component {
    constructor(props) {
        super(props);
        this.handleRoute = this.handleRoute.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleSelectInstance(slug) {
        console.log(slug);
        this.props.switchInstance(slug);
    }

    handleRoute(slug) {
        this.props.history.push(slug);
    }

    handleLogout() {
        this.props.logout();
    }

    render() {
        const iconColumns = (
            <i className="fal fa-archive" />
        );

        const ToolMenuDropdown = (
            <div style={{ maxHeight: '50vh', overflowY : 'auto' }}>
                <Menu>
                    <MenuItem text="Studio" onClick={this.handleRoute.bind(this, '/studio')} />
                    <MenuItem text="User details" onClick={this.handleRoute.bind(this, '/user')} />
                    <MenuDivider />
                    <MenuItem icon="box" text="Archive" />
                    <MenuItem icon="column-layout" text="Workflow" />
                    <MenuItem icon="cog" text="Settings" />
                </Menu>
            </div>
        );

        return (
            <Popover content={ToolMenuDropdown} position={Position.BOTTOM_LEFT}>
                <Button intent="minimal" icon={iconColumns} rightIcon="chevron-down" text="Archive" />
            </Popover>
        );
    }
}

const mapStateToProps = state => ({
    acls : state.auth.acls,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    switchInstance,
    logout,
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ToolMenu));