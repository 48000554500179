/**
 * @copyright Lookbooks Media, Inc. 2018, 2019
 */

import React from 'react'
import './assets/styles/app.css';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom';

import { initializeOnStart } from './modules/auth'

import { LoadingApp } from './framework/loadingmodal'

import Header from './containers/header';
import PrivateRoute from './containers/privateroute';
import User from './containers/user';
import Studio from './containers/studio';
import Login from './containers/login';

class App extends React.Component {
    componentDidMount() {
        this.props.initializeOnStart();
    }

    render() {
        if (this.props.initialized) {
            return (
                <div className="App">
                    <Header />
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <PrivateRoute exact path="/user" component={User} />
                    <PrivateRoute exact path="/studio" component={Studio} />
                    <footer className="footer" />
                </div>
            );
        } else {
            return (
                <LoadingApp />
            )
        }
    }
}

const mapStateToProps = state => ({
    initialized : state.auth.initialized
})

const mapDispatchToProps = dispatch => bindActionCreators({
    initializeOnStart
}, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(App));