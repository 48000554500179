/**
 * @copyright Lookbooks Media, Inc. 2019
 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SideMenu from '../framework/sidemenu';
import SectionHeader from '../framework/sectionheader';

import StudioTree from '../components/studiotree';
import ContentView from '../components/contentview';

import Inspector from '../components/inspector';

import { getDefaultMenuBranches } from '../modules/studio';

class Studio extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.tree && !this.props.treeLoading) {
            this.props.getDefaultMenuBranches();
        }

        return (
            <React.Fragment>
                <div className="main">
                    <SideMenu>
                        <StudioTree />
                    </SideMenu>
                    <div id="main" className="main-col main-center">
                        {this.props.currentContentId &&
                            <ContentView
                                id={this.props.currentContentId}
                                loading={this.props.loadingContentId}
                                content={this.props.currentContent}
                                session={this.props.session}
                            />
                        }
                    </div>
                    <div id="inspector" className="main-col main-right">
                        <Inspector />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const extractCurrentContent = state => {
    if (!state.studio.loadingContentId && state.studio.currentContentId) {
        if (state.studio.contents.hasOwnProperty(state.studio.currentContentId)) {
            return state.studio.contents[state.studio.currentContentId];
        }
    }
    return false;
}

const mapStateToProps = state => ({
    session : state.auth.session,

    forceRefresh : state.studio.forceRefresh,

    tree : state.studio.tree,
    treeLoading : state.studio.treeLoading,

    currentContentId : state.studio.currentContentId,
    loadingContentId : state.studio.loadingContentId,
    currentContent : extractCurrentContent(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getDefaultMenuBranches,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Studio);
